import { ColumnProps } from "antd/lib/table";
import { HTMLAttributes, ReactNode } from "react";
import { Dayjs } from "dayjs";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeAddressElementChangeEvent,
} from "@stripe/stripe-js";
import {
  ValidationErrors,
  PageInfo,
  DefaultErrorSuccessResponse,
  WorkingTimeEnum,
  WorkingCondition,
  WeekDayEnum,
} from "./Common";
import { AnnualLeaveEnum } from "./Leave";
import { Label } from "./Employee";

export interface Tag {
  id: string;
  name: string;
}

export type Department = Tag;
export type Location = Tag;
export type JobTitle = Tag;

export interface DepartmentResponse {
  departments: {
    nodes: Department[];
    edges: Object;
    pageInfo: PageInfo;
  };
}
export interface LocationResponse {
  locations: {
    nodes: Location[];
    edges: Object;
    pageInfo: PageInfo;
  };
}

export interface JobTitleResponse {
  jobTitles: {
    nodes: JobTitle[];
    edges: Object;
    pageInfo: PageInfo;
  };
}

export interface EditableCellProps extends HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "text";
  record: Tag;
  index: number;
  children: ReactNode;
}

export interface CreateDepartmentResponse {
  createDepartment: {
    department: Department;
    errors: ValidationErrors;
  };
}
export interface CreateLocationResponse {
  createLocation: {
    location: Location;
    errors: ValidationErrors;
  };
}

export interface CreateJobTitleResponse {
  createJobTitle: {
    jobTitle: JobTitle;
    errors: ValidationErrors;
  };
}
export interface UpdateDepartmentResponse {
  updateDepartment: {
    department: Department;
    errors: ValidationErrors;
  };
}

export interface UpdateLocationResponse {
  updateLocation: {
    location: Location;
    errors: ValidationErrors;
  };
}
export interface UpdateJobTitleResponse {
  updateJobTitle: {
    jobTitle: JobTitle;
    errors: ValidationErrors;
  };
}
export interface DestroyJobTitle {
  destroyJobTitle: DefaultErrorSuccessResponse;
}
export interface DestroyLocation {
  destroyLocation: DefaultErrorSuccessResponse;
}
export interface DestroyDepartment {
  destroyDepartment: DefaultErrorSuccessResponse;
}
export interface LabelRefetch {
  refetch(): void;
}

export type ExtendedColumnsType = {
  editable?: boolean;
} & ColumnProps<Tag>;

export declare namespace IntlType {
  type Key = "calendar" | "collation" | "currency" | "numberingSystem" | "timeZone" | "unit";

  function supportedValuesOf(input: Key): string[];
}

export enum ShowEmployeeCalendarType {
  All = "1",
  SameDepartment = "2",
  SameLocation = "4",
}

export const showEmployeeCalendarLabel = {
  [ShowEmployeeCalendarType.All]: "All",
  [ShowEmployeeCalendarType.SameDepartment]: "Same department",
  [ShowEmployeeCalendarType.SameLocation]: "Same location",
};

export interface DefaultSettingResponse {
  fetchDefaultSetting: {
    id: string;
    annualLeave: AnnualLeaveEnum;
    holidaysPerYear: number;
    probationaryPeriod: number;
    timeZone: string;
    selectedCalendars: number;
    workingCondition: WorkingCondition;
  };
}

export interface WorkingConditionPayload {
  startTime?: Dayjs;
  endTime?: Dayjs;
  day?: boolean;
  lunchBreak?: number | null;
}

export interface DefaultSettingPayload {
  annualLeave: AnnualLeaveEnum;
  daysPerWeek?: WeekDayEnum[];
  endTime?: string;
  holidaysPerYear: number;
  hoursPerWeek: number;
  probationaryPeriod: number;
  startTime?: string;
  timeZone: string;
  selectedCalendars: number;
  lunchBreak?: number | null;
  workingCondition?: Record<string, WorkingConditionPayload>;
  workingTime: WorkingTimeEnum;
}

export interface UpsertDefaultSettingResponse {
  upsertDefaultSetting: {
    errors?: ValidationErrors;
    defaultSetting: DefaultSettingResponse;
  };
}

export interface DefaultSettingInitialValue extends Omit<DefaultSettingPayload, "startTime" | "endTime"> {
  startTime?: Dayjs;
  endTime?: Dayjs;
  hoursPerWeek: number;
}

export interface RolesSettings {
  editable: boolean;
  name: string;
  position: number;
  role: Tag;
}

export interface FetchRolesSettings {
  roleSettings: {
    nodes: RolesSettings[];
    edges: Object;
    pageInfo: PageInfo;
  };
}

export interface RoleConnectionResponse {
  [x: string]: DefaultErrorSuccessResponse;
}
export interface CreateUserRoleConnectionResponse {
  data: RoleConnectionResponse;
}

export interface DestroyUserRoleConnectionResponse {
  data: RoleConnectionResponse;
}

export interface DefaultSettingRef {
  resetForm(): void;
  setIsFieldsChanged(value: boolean): void;
  isFieldsChanged: boolean;
  loading: boolean;
}

export interface AssignRoleArg {
  roleName?: string;
  roleId?: string;
  deletedRoleId?: string;
  deletedRoleName?: string;
  userId: string;
  selectedRole: Label[];
}

export interface AssignRoleData {
  roleName: string[];
  roleId: string[];
  deletedRoleId: string[];
  deletedRoleName: string[];
  userId: string;
  selectedRole: Label[];
}

export interface ModifyAssignRoleData {
  [userId: string]: string[];
}

export interface AssignRoleRef {
  resetForm(): void;
  setIsFieldsChanged(value: boolean): void;
  isFieldsChanged: boolean;
}

export enum DefaultNotificationSettingsEventType {
  LeaveRequested = "LEAVE_REQUESTED",
  IsOffSummary = "IS_OFF_SUMMARY",
  ProbationaryPeriodEnding = "PROBATIONARY_PERIOD_ENDING",
  BirthDays = "BIRTHDAY",
  Starting = "STARTING",
  Finishing = "FINISHING",
  WorkAnniversary = "WORK_ANNIVERSARY",
}

export const DefaultNotificationSettingsEventTypeLabel: Record<DefaultNotificationSettingsEventType, string> = {
  [DefaultNotificationSettingsEventType.LeaveRequested]: "Employee leave request",
  [DefaultNotificationSettingsEventType.IsOffSummary]: "Daily summary of who is off",
  [DefaultNotificationSettingsEventType.ProbationaryPeriodEnding]: "Employee probation period ending",
  [DefaultNotificationSettingsEventType.BirthDays]: "Birthdays coming up in",
  [DefaultNotificationSettingsEventType.Starting]: "New starters in",
  [DefaultNotificationSettingsEventType.Finishing]: "People finishing",
  [DefaultNotificationSettingsEventType.WorkAnniversary]: "Work anniversaries",
};

export interface DefaultNotificationSettings {
  eventType: DefaultNotificationSettingsEventType;
  id: string;
  isEmail: boolean;
  isWeb: boolean;
  period: number;
}

export interface DefaultNotificationSettingsResponse {
  defaultNotificationSettings: {
    nodes: DefaultNotificationSettings[];
    edges: Object;
    pageInfo: PageInfo;
  };
}

export interface UpdateDefaultNotificationSettingResponse {
  [x: string]: {
    defaultNotificationSetting: DefaultNotificationSettings;
    errors: ValidationErrors;
  };
}

export interface UpdateDefaultNotificationSetting {
  data: {
    [x: string]: DefaultNotificationSettings;
  };
}

export interface DefaultNotificationSettingRef {
  resetForm(): void;
  setIsFieldsChanged(value: boolean): void;
  isFieldsChanged: boolean;
  loading: boolean;
}

export interface BillingRef {
  resetForm(): void;
  setIsFieldsChanged(): void;
  isFieldsChanged: boolean;
  refetchCustomerDetails(): void;
  setIsSaveDisabled(): void;
}

export interface PaymentHistoryDataType {
  id: string;
  date: string;
  type: string;
  price: number;
}

export enum PricesIntervalEnum {
  Month = "MONTH",
  Year = "YEAR",
}

export const PricesIntervalLabel: Record<PricesIntervalEnum, string> = {
  [PricesIntervalEnum.Month]: "Monthly",
  [PricesIntervalEnum.Year]: "Annual",
};

export type PriceType = {
  currency: string;
  id: string;
  interval: PricesIntervalEnum;
  unitAmount: number;
};
export interface BillingPackages {
  id: string;
  name: string;
  prices: PriceType[];
}

export interface BillingFormArg {
  cardHolderName: string;
  cardNumber: StripeCardNumberElementChangeEvent;
  cvv: StripeCardCvcElementChangeEvent;
  email: string;
  quantity: number;
  expirationDate: StripeCardExpiryElementChangeEvent;
  interval: PricesIntervalEnum;
  businessDetail: StripeAddressElementChangeEvent;
}

export interface BillingPackagesResponse {
  products: {
    nodes: BillingPackages[];
    edges: Object;
    pageInfo: PageInfo;
  };
}

export interface PaymentMethod {
  id: string;
  cardBrand: string;
  cardExpMonth: number;
  cardExpYear: number;
  cardLast4: string;
  default: boolean;
}

export interface Invoices {
  currency: string;
  failed: boolean;
  fromDate: string;
  id: string;
  invoicePdf: string;
  total: number;
}

export interface SubscriptionData {
  id: string;
  currentPeriodEnd: string;
  invoices: Invoices[];
  price: PriceType;
  productName: string;
  quantity: number;
  promotionCode?: PromotionCode;
}

export interface BillingCustomer {
  city: string;
  country: string;
  email: string;
  id: string;
  line1: string;
  line2: string;
  name: string;
  phone: string;
  postalCode: string;
  state: string;
  paymentMethods: PaymentMethod[];
  subscription: SubscriptionData;
}

export interface UpsertBillingCustomerResponse {
  upsertCustomer: { customer: BillingCustomer; errors: ValidationErrors };
}

export interface BillingCustomerResponse {
  fetchCustomer: BillingCustomer;
}

export interface CreateSetupIntentResponse {
  createSetupIntent: {
    clientSecret: string;
    success: boolean;
  };
}

export interface DefaultCardDetails {
  cardBrand: string;
  cardExp: string;
  cardLast4: string;
}

export interface SubscriptionResponse {
  upsertSubscription: {
    subscription: SubscriptionData;
    errors: ValidationErrors;
  };
}

export interface BillingHistory {
  id: string;
  fromDate: string;
  type: string;
  price: number;
  invoicePdf: string;
  currency: string;
}

export interface DestroyCustomerResponse {
  destroyCustomer: {
    success: boolean;
  };
}

export enum AdminSettingTab {
  DefaultSettings = "DEFAULT_SETTINGS",
  AssignSuperadminMangers = "ASSIGN_SUPERADMIN_MANAGERS",
  Labels = "LABELS",
  EmployeeNotifications = "EMPLOYEE_NOTIFICATIONS",
  Billing = "BILLING",
}

export interface BillingDetailsRef {
  refetch(): void;
}

export type CurrentPackage = {
  currentPeriodEnd: string;
  price: PriceType;
  quantity: number;
};

export enum PromotionCodesPaymentTypeEnum {
  Annually = "ANNUAL",
  Monthly = "MONTHLY",
}

export interface PromotionCode {
  id: string;
  code: string;
  coupon: {
    id: string;
    percentOff: number;
  };
  paymentType: PromotionCodesPaymentTypeEnum;
}

export interface FetchPromotionCodeResponse {
  fetchPromotionCode: PromotionCode;
}
