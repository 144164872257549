export interface ValidationErrors {
  details: string;
  fullMessages: Array<string>;
}

export enum WeekDayEnum {
  Monday = "MONDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
  Thursday = "THURSDAY",
  Friday = "FRIDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
}

export interface WorkingDays {
  endTime: string;
  lunchBreak: number | null;
  startTime: string;
  weekDay: WeekDayEnum;
}

export enum WorkingTimeEnum {
  Fixed = "FIXED",
  Variable = "VARIABLE",
}
export const workingTimeLabel: Record<WorkingTimeEnum, string> = {
  [WorkingTimeEnum.Fixed]: "Fixed daily time",
  [WorkingTimeEnum.Variable]: "Variable daily time",
};

export interface WorkingCondition {
  workingDays: WorkingDays[];
  workingTime: WorkingTimeEnum;
}

export interface VariableDailyTimeShowFieldReset {
  variableDailyTimeShowFieldReset(): void;
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: string;
  /** When paginating forwards, are there more items? */
  hasNextPage: boolean;
  /** When paginating backwards, are there more items? */
  hasPreviousPage: boolean;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: string;
};

export type DefaultErrorSuccessResponse = { errors?: ValidationErrors; success: boolean };
