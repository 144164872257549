import dayjs from "dayjs";
import { getUserTimeZone } from "utils";
import { AnnualLeaveEnum } from "model/Leave";
import { WeekDayEnum, WorkingTimeEnum } from "model/Common";
import TIME_FORMAT from "./timeFormat";

const defaultSettingInitialValues = {
  holidaysPerYear: 15,
  probationaryPeriod: 11,
  hoursPerWeek: 40,
  startTime: dayjs("9 00 am", TIME_FORMAT.timeWithPeriodSecondary),
  endTime: dayjs("5 30 pm", TIME_FORMAT.timeWithPeriodSecondary),
  daysPerWeek: [
    WeekDayEnum.Monday,
    WeekDayEnum.Tuesday,
    WeekDayEnum.Wednesday,
    WeekDayEnum.Thursday,
    WeekDayEnum.Friday,
  ],
  annualLeave: AnnualLeaveEnum.AccruedLeaveOnly,
  timeZone: getUserTimeZone(),
  selectedCalendars: 0,
  workingTime: WorkingTimeEnum.Fixed,
};

const roles = {
  superUser: "Superuser",
  manager: "Manager",
};

const roleNames = {
  superAdmin: "Superadmin",
  manager: "Manager",
  leaveRequest: "Leave request",
};

export const ADMIN_SETTING = { defaultSettingInitialValues, roles, roleNames };
export default ADMIN_SETTING;
