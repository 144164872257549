import { Checkbox, Col, Form, FormInstance, InputNumber, Row, Table } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ColumnsType } from "antd/es/table";
import InputCheckboxButton from "components/Inputs/InputCheckboxButton/InputCheckboxButton";
import InputTimePicker from "components/Inputs/InputTimePicker/InputTimePicker";
import ALERTS from "config/alerts";
import EMPLOYEE from "constants/employee";
import TIME_FORMAT from "constants/timeFormat";
import { VariableDailyTimeShowFieldReset, WorkingTimeEnum } from "model/Common";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { calculateHoursPerWeek } from "utils";

type WorkingConditionFormProps = {
  workingTime: WorkingTimeEnum;
  form: FormInstance;
};

const WorkingConditionFormFields = forwardRef<VariableDailyTimeShowFieldReset, WorkingConditionFormProps>(
  ({ workingTime, form }, ref) => {
    const [isShowingDayTime, setIsShowingDayTime] = useState<string[]>([]);
    const { id } = useParams();

    const workingDaysOnChangeHandler = (e: CheckboxChangeEvent, day: string) => {
      if (e.target.checked) {
        setIsShowingDayTime((prev: string[]) => [...prev, day.toUpperCase()]);
      } else {
        setIsShowingDayTime((prev: string[]) => prev.filter((item) => item !== day.toUpperCase()));
        form.resetFields([
          ["workingCondition", day.toUpperCase(), "startTime"],
          ["workingCondition", day.toUpperCase(), "endTime"],
          ["workingCondition", day.toUpperCase(), "lunchBreak"],
        ]);
      }
    };

    const handleTimeChange = () => {
      const workingCondition = form.getFieldValue("workingCondition");
      const { daysPerWeek, startTime, endTime, lunchBreak } = form.getFieldsValue([
        "daysPerWeek",
        "startTime",
        "endTime",
        "lunchBreak",
      ]);

      const hoursPerWeek =
        workingTime === WorkingTimeEnum.Variable
          ? calculateHoursPerWeek({ workingCondition })
          : calculateHoursPerWeek({
              daysPerWeek: daysPerWeek?.length,
              startTimeData: startTime,
              endTimeData: endTime,
              lunchBreak,
            });
      form.setFieldValue("hoursPerWeek", parseFloat(hoursPerWeek.toFixed(2)));
    };

    useImperativeHandle(ref, () => ({
      variableDailyTimeShowFieldReset() {
        setIsShowingDayTime([]);
      },
    }));

    const columns: ColumnsType<{ day: string }> = [
      {
        title: "Select days available for work",
        dataIndex: "days",
        width: "40%",
        render: (_, record) => (
          <Form.Item
            name={["workingCondition", record?.day.toUpperCase(), "day"]}
            colon={false}
            valuePropName="checked"
            className="mt-2 mb-0"
          >
            <Checkbox
              onChange={(e) => {
                workingDaysOnChangeHandler(e, record?.day);
                handleTimeChange();
              }}
            >
              {record?.day}
            </Checkbox>
          </Form.Item>
        ),
      },
      {
        title: "Start time",
        dataIndex: "startTime",
        width: "20%",
        render: (_, record) =>
          (isShowingDayTime.includes(record?.day.toUpperCase()) ||
            form.getFieldValue(["workingCondition", record?.day.toUpperCase(), "day"])) && (
            <Form.Item
              name={["workingCondition", record?.day.toUpperCase(), "startTime"]}
              colon={false}
              rules={[ALERTS.required]}
              required={false}
              className="m-0"
            >
              <InputTimePicker
                className="w-100"
                changeOnBlur
                format={TIME_FORMAT.timeFormatWithoutPeriod}
                use12Hours
                minuteStep={30}
                data-testid="startTime"
                showNow={false}
                getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                form={form}
                name={["workingCondition", record?.day.toUpperCase(), "startTime"]}
                onChange={handleTimeChange}
              />
            </Form.Item>
          ),
      },
      {
        title: "End time",
        dataIndex: "endTime",
        width: "20%",
        render: (_, record) =>
          (isShowingDayTime.includes(record?.day.toUpperCase()) ||
            form.getFieldValue(["workingCondition", record?.day.toUpperCase(), "day"])) && (
            <Form.Item
              name={["workingCondition", record?.day.toUpperCase(), "endTime"]}
              colon={false}
              rules={[ALERTS.required]}
              required={false}
              className="m-0"
            >
              <InputTimePicker
                className="w-100"
                changeOnBlur
                format={TIME_FORMAT.timeFormatWithoutPeriod}
                use12Hours
                minuteStep={30}
                data-testid="endTime"
                showNow={false}
                getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                form={form}
                name={["workingCondition", record?.day.toUpperCase(), "endTime"]}
                onChange={handleTimeChange}
              />
            </Form.Item>
          ),
      },
      {
        title: "Break (min)",
        dataIndex: "lunchBreak",
        width: "20%",
        render: (_, record) =>
          (isShowingDayTime.includes(record?.day.toUpperCase()) ||
            form.getFieldValue(["workingCondition", record?.day.toUpperCase(), "day"])) && (
            <Form.Item
              name={["workingCondition", record?.day.toUpperCase(), "lunchBreak"]}
              colon={false}
              className="m-0"
            >
              <InputNumber
                min={0}
                controls={false}
                className="w-100"
                data-testid="lunchBreak"
                onChange={handleTimeChange}
              />
            </Form.Item>
          ),
      },
    ];

    return (
      <StyledRow>
        <Col span={24}>
          {workingTime === WorkingTimeEnum.Fixed && (
            <>
              <Row gutter={[38, 0]} data-testid="abc">
                <Col md={24} lg={16} xl={14}>
                  <Form.Item
                    label="Default days of work"
                    name="daysPerWeek"
                    colon={false}
                    rules={[ALERTS.required]}
                    required={!!id}
                    className="vertical-layout"
                  >
                    <InputCheckboxButton
                      options={EMPLOYEE.days}
                      className="w-100"
                      data-testid="daysPerWeek"
                      onChange={handleTimeChange}
                    />
                  </Form.Item>
                </Col>
                <Col md={24} lg={8} xl={7}>
                  <Form.Item label="Break (min)" name="lunchBreak" colon={false} className="vertical-layout">
                    <InputNumber
                      min={0}
                      controls={false}
                      className="w-100"
                      data-testid="lunchBreak"
                      onChange={handleTimeChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[38, 0]}>
                <Col md={24} lg={8} xl={7}>
                  <Form.Item
                    label="Start time"
                    name="startTime"
                    colon={false}
                    wrapperCol={{ lg: 11, xl: 10 }}
                    rules={[ALERTS.required]}
                    required={!!id}
                    className="vertical-layout mb-0"
                  >
                    <InputTimePicker
                      className="w-100"
                      changeOnBlur
                      format={TIME_FORMAT.timeFormatWithoutPeriod}
                      use12Hours
                      minuteStep={30}
                      data-testid="startTime"
                      showNow={false}
                      getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                      form={form}
                      name="startTime"
                      onChange={handleTimeChange}
                    />
                  </Form.Item>
                </Col>
                <Col md={24} lg={8} xl={7}>
                  <Form.Item
                    label="End time"
                    name="endTime"
                    colon={false}
                    wrapperCol={{ lg: 11, xl: 10 }}
                    rules={[ALERTS.required]}
                    required={!!id}
                    className="vertical-layout mb-0"
                  >
                    <InputTimePicker
                      className="w-100"
                      changeOnBlur
                      format={TIME_FORMAT.timeFormatWithoutPeriod}
                      use12Hours
                      minuteStep={30}
                      data-testid="endTime"
                      showNow={false}
                      getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                      form={form}
                      name="endTime"
                      onChange={handleTimeChange}
                    />
                  </Form.Item>
                </Col>
                <Col md={24} lg={8} xl={7}>
                  <Form.Item label="Hours per week" name="hoursPerWeek" colon={false} className="vertical-layout mb-0">
                    <InputNumber min={0} className="w-100" data-testid="hoursPerWeek" disabled />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {workingTime === WorkingTimeEnum.Variable && (
            <>
              <Row gutter={[38, 0]}>
                <Col md={24} xl={21}>
                  <Form.Item colon={false}>
                    <Table
                      dataSource={EMPLOYEE.days.map((item) => ({ day: item.fullName })) ?? []}
                      columns={columns}
                      rowKey="day"
                      pagination={false}
                      size="small"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[38, 0]}>
                <Col md={24} lg={8} xl={7}>
                  <Form.Item label="Hours per week" name="hoursPerWeek" colon={false} className="vertical-layout mb-0">
                    <InputNumber min={0} className="w-100" data-testid="hoursPerWeek" disabled />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </StyledRow>
    );
  },
);
export default WorkingConditionFormFields;
const StyledRow = styled(Row)`
  & .vertical-layout .ant-row.ant-form-item-row {
    display: block;
    .ant-form-item-control,
    .ant-form-item-label {
      width: 100%;
      max-width: 100%;
    }
  }
  & .ant-table .ant-table-tbody tr td.ant-table-cell:first-child,
  & .ant-table .ant-table-thead tr th:first-child,
  & .ant-table .ant-table-thead tr td:first-child {
    padding-left: 0;
  }
  & .ant-table .ant-table-tbody tr:first-child td.ant-table-cell {
    padding-top: 16px;
    height: 56px;
  }
  & .ant-table .ant-table-tbody tr td.ant-table-cell {
    border-bottom: none;
    vertical-align: top;
    height: 45px;
    padding: 4px 8px;
  }
  & .ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
    background: none;
  }
`;
