import DownloadSvg from "./DownloadSvg";
import ProgressScoreSvg from "./ProgressScoreSvg";
import StarSvg from "./StarSvg";
import DownSvg from "./DownSvg";
import ArrowRightSvg from "./ArrowRightSvg";
import ArrowLeftSvg from "./ArrowLeftSvg";
import HomeSvg from "./HomeSvg";
import SearchSvg from "./SearchSvg";
import EditSvg from "./EditSvg";
import RemoveSvg from "./RemoveSvg";
import InfoSvg from "./InfoSvg";
import SickLeave from "./SickLeave";
import CareersLeave from "./CareersLeave";
import PaternityLeave from "./PaternityLeave";
import JuryService from "./JuryService";
import AdoptiveLeave from "./AdoptiveLeave";
import CompassionateLeave from "./CompassionateLeave";
import ForceMajeureLeave from "./ForceMajeureLeave";
import MaternityLeave from "./MaternityLeave";
import OtherLeave from "./OtherLeave";
import ParentalLeave from "./ParentalLeave";
import TimeInLieuLeave from "./TimeInLieuLeave";
import UnpaidLeave from "./UnpaidLeave";
import SettingsSvg from "./SettingsSvg";
import UserOutlinedSvg from "./UserOutlinedSvg";
import UserFilledSvg from "./UserFilledSvg";
import BirthdaySvg from "./BirthdaySvg";
import AnniversarySvg from "./AnniversarySvg";
import PopOverSvg from "./PopOverSvg";
import CheckOutlinedSvg from "./CheckOutlinedSvg";
import SuccessCheckSvg from "./SuccessCheckSvg";
import DocumentSvg from "./DocumentSvg";
import CalendarSvg from "./CalendarSvg";
import CalendarBlackSvg from "./CalendarBlackSvg";
import BellSvg from "./BellSvg";
import UploadComplete from "./UploadCompleteSvg";
import DangerSvg from "./DangerSvg";
import DocumentPageSvg from "./DocumentPageSvg";
import VisaCard from "./VisaCard";
import MasterCard from "./MasterCard";
import AmexCard from "./AmexCard";
import DiscoverCard from "./DiscoverCard";
import DinersCard from "./DinersCard";
import JcbCard from "./JcbCard";
import UnionPayCard from "./UnionPayCard";
import WarningSvg from "./WarningSvg";
import NoSubscription from "./NoSubscription";
import DeleteSvg from "./DeleteSvg";
import InfoOutlineSvg from "./InfoOutlineSvg";

const CustomIcons = {
  HomeSvg,
  DownloadSvg,
  ProgressScoreSvg,
  StarSvg,
  DownSvg,
  ArrowRightSvg,
  ArrowLeftSvg,
  SearchSvg,
  EditSvg,
  RemoveSvg,
  InfoSvg,
  SickLeave,
  CareersLeave,
  PaternityLeave,
  JuryService,
  AdoptiveLeave,
  CompassionateLeave,
  ForceMajeureLeave,
  MaternityLeave,
  OtherLeave,
  ParentalLeave,
  TimeInLieuLeave,
  UnpaidLeave,
  SettingsSvg,
  UserOutlinedSvg,
  UserFilledSvg,
  BirthdaySvg,
  AnniversarySvg,
  PopOverSvg,
  CheckOutlinedSvg,
  SuccessCheckSvg,
  DocumentSvg,
  CalendarSvg,
  CalendarBlackSvg,
  BellSvg,
  UploadComplete,
  DangerSvg,
  DocumentPageSvg,
  VisaCard,
  MasterCard,
  AmexCard,
  DiscoverCard,
  DinersCard,
  JcbCard,
  UnionPayCard,
  WarningSvg,
  NoSubscription,
  DeleteSvg,
  InfoOutlineSvg,
};
export default CustomIcons;
