import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "antd/lib/form/Form";
import ADMIN_SETTING from "constants/adminSetting";
import TIME_FORMAT from "constants/timeFormat";
import dayjs from "dayjs";
import {
  DefaultSettingInitialValue,
  DefaultSettingPayload,
  DefaultSettingRef,
  DefaultSettingResponse,
  ShowEmployeeCalendarType,
  UpsertDefaultSettingResponse,
  WorkingConditionPayload,
} from "model/AdminSetting";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useNotify } from "services/api";
import { FETCH_DEFAULT_SETTING, UPSERT_DEFAULT_SETTING } from "services/graphql/adminSetting";
import { calculateHoursPerWeek, expectNumber, modifyWorkingConditionPayload } from "utils";
import { WorkingDays, WorkingTimeEnum } from "model/Common";
import DefaultSettingsForm from "./DefaultSettings/DefaultSettingsForm";

const DefaultSettingsTab = forwardRef<DefaultSettingRef>((_, ref) => {
  const [form] = useForm();
  const [showEmployeeCalendarValue, setShowEmployeeCalendarValue] = useState<number[]>([]);
  const [isFieldsChanged, setIsFieldsChanged] = useState<boolean>(false);
  const [formInitialValues, setFormInitialValues] = useState<DefaultSettingInitialValue>();
  const notify = useNotify();

  const modifyResponseData = (response: DefaultSettingResponse) => {
    const { workingCondition, id, ...otherResponse } = response.fetchDefaultSetting;
    const { workingTime, workingDays } = workingCondition;
    const baseData = { ...otherResponse, workingTime };

    const formatWorkingDay = ({ startTime, endTime, lunchBreak }: WorkingDays) =>
      startTime
        ? {
            day: true,
            startTime: dayjs(startTime, TIME_FORMAT.timeFormatWith24Hour),
            endTime: dayjs(endTime, TIME_FORMAT.timeFormatWith24Hour),
            lunchBreak,
          }
        : { day: false };

    if (workingTime === WorkingTimeEnum.Fixed) {
      const [{ startTime, endTime, lunchBreak }] = workingDays;
      const modifyData = {
        daysPerWeek: workingDays.map(({ weekDay }) => weekDay),
        startTime: dayjs(startTime, TIME_FORMAT.timeFormatWith24Hour),
        endTime: dayjs(endTime, TIME_FORMAT.timeFormatWith24Hour),
        lunchBreak,
      };
      return {
        ...baseData,
        ...modifyData,
        hoursPerWeek: calculateHoursPerWeek({
          daysPerWeek: modifyData.daysPerWeek.length,
          startTimeData: modifyData.startTime,
          endTimeData: modifyData.endTime,
          lunchBreak: modifyData.lunchBreak,
        }),
      };
    }
    const modifyWorkingCondition = workingDays.reduce(
      (acc, day) => ({
        ...acc,
        [day.weekDay]: formatWorkingDay(day),
      }),
      {} as Record<string, WorkingConditionPayload>,
    );

    return {
      ...baseData,
      workingCondition: modifyWorkingCondition,
      hoursPerWeek: calculateHoursPerWeek({ workingCondition: modifyWorkingCondition }),
    };
  };

  const { refetch } = useQuery<DefaultSettingResponse>(FETCH_DEFAULT_SETTING, {
    onCompleted: (response) => {
      if (response.fetchDefaultSetting) {
        setFormInitialValues(modifyResponseData(response));
        const selectedCalendars =
          response.fetchDefaultSetting.selectedCalendars > 4
            ? [2, 4]
            : [response.fetchDefaultSetting.selectedCalendars];
        setShowEmployeeCalendarValue(selectedCalendars);
      } else {
        setFormInitialValues(ADMIN_SETTING.defaultSettingInitialValues);
      }
    },
  });

  const [mutate, { loading }] = useMutation<UpsertDefaultSettingResponse>(UPSERT_DEFAULT_SETTING, {
    onCompleted: (response) => {
      if (response?.upsertDefaultSetting) {
        if (response.upsertDefaultSetting?.errors?.fullMessages?.length) {
          response.upsertDefaultSetting.errors.fullMessages.map((error: string) => notify.error(undefined, error));
        } else {
          notify.success({ message: "Changes Saved successfully." });
          setIsFieldsChanged(false);
          refetch();
        }
      }
    },
  });

  const onFinish = (data: DefaultSettingPayload) => {
    const {
      daysPerWeek,
      workingCondition: workingConditionPayload,
      startTime,
      endTime,
      hoursPerWeek,
      lunchBreak,
      ...restPayloadData
    } = data;
    const workingDays = modifyWorkingConditionPayload({
      workingTime: restPayloadData.workingTime,
      daysPerWeek,
      startTimeData: startTime,
      endTimeData: endTime,
      lunchBreak,
      workingConditionPayload,
    });
    const selectedCalendars = showEmployeeCalendarValue.reduce((a, b) => a + b, 0);
    mutate({ variables: { ...restPayloadData, selectedCalendars, workingDays } });
  };

  const onChange = (value: number, checked: boolean) => {
    if (value === expectNumber(ShowEmployeeCalendarType.All)) {
      setShowEmployeeCalendarValue(checked ? [value] : []);
    }
    if (value !== expectNumber(ShowEmployeeCalendarType.All)) {
      if (showEmployeeCalendarValue.includes(expectNumber(ShowEmployeeCalendarType.All))) {
        setShowEmployeeCalendarValue((prev) =>
          prev.filter((option) => option !== expectNumber(ShowEmployeeCalendarType.All)),
        );
      }
      if (checked) {
        setShowEmployeeCalendarValue((prev) => [...prev, value]);
      } else {
        setShowEmployeeCalendarValue((prev) => prev.filter((option) => option !== value));
      }
    }
  };

  useImperativeHandle(ref, () => ({
    resetForm() {
      form.resetFields();
      if (formInitialValues?.selectedCalendars) {
        const selectedCalendars =
          formInitialValues.selectedCalendars > expectNumber(ShowEmployeeCalendarType.SameLocation)
            ? [
                expectNumber(ShowEmployeeCalendarType.SameDepartment),
                expectNumber(ShowEmployeeCalendarType.SameLocation),
              ]
            : [formInitialValues.selectedCalendars];
        setShowEmployeeCalendarValue(selectedCalendars);
      }
    },
    setIsFieldsChanged(value) {
      setIsFieldsChanged(value);
    },
    isFieldsChanged,
    loading,
  }));

  useEffect(() => {
    if (formInitialValues) {
      form.resetFields();
    }
  }, [form, formInitialValues]);

  return (
    <DefaultSettingsForm
      formInitialValues={formInitialValues}
      isFieldsChanged={isFieldsChanged}
      onFinish={onFinish}
      selectedCalendarsOnChange={onChange}
      setIsFieldsChanged={setIsFieldsChanged}
      showEmployeeCalendarValue={showEmployeeCalendarValue}
      form={form}
    />
  );
});

export default DefaultSettingsTab;
