import { PageInfo, WorkingCondition } from "./Common";
import { LeaveRequestsDayCompleteness, LeaveRequestsReason, LeaveRequestsStatus } from "./Leave";

export interface UserLeaveRequest {
  reason: LeaveRequestsReason;
  fromDate: string;
  toDate: string;
  fromTime?: string;
  toTime?: string;
  dayCompleteness: LeaveRequestsDayCompleteness;
  employeeNote?: { body: string; id: string } | null;
  status: LeaveRequestsStatus;
  usedDays: number;
  workingCondition: WorkingCondition;
}

export interface DaysInMonth {
  date: string;
  day: string;
  isToday: boolean;
}

export type PublicHolidaysType = {
  date: string;
  name: string;
};

export interface CalendarDataType {
  id: string;
  name: string;
  jobTitle: {
    name?: string;
  };
  profileImageUrl: string | null;
  leaveRequests: UserLeaveRequest[];
  publicHolidays: PublicHolidaysType[];
  workingCondition: WorkingCondition;
}

export interface DataType {
  id: string;
  name: string;
  jobTitle: { name: string };
}

export interface PeopleOff extends DataType {
  leaveRequests: {
    reason: LeaveRequestsReason;
    fromDate: string;
    toDate: string;
  }[];
}
export interface ProbationaryPeriodEndingThisWeek extends DataType {
  startedAt: string;
  probationaryPeriod: number;
}
export interface StartingThisWeek extends DataType {
  startedAt: string;
}
export interface BirthdaysThisWeek extends DataType {
  dateOfBirth: string;
}
export interface WorkAnniversaryThisWeek extends DataType {
  startedAt: string;
}

export interface TodayDrawerFilter {
  departments?: string[];
  locations?: string[];
}

export interface TodayDrawerResponse {
  peopleOff: { nodes: PeopleOff[] };
  probationaryPeriodEndingThisWeek: { nodes: ProbationaryPeriodEndingThisWeek[] };
  startingThisWeek: { nodes: StartingThisWeek[] };
  birthdaysThisWeek: { nodes: BirthdaysThisWeek[] };
  workAnniversaryThisWeek: { nodes: WorkAnniversaryThisWeek[] };
}

export enum EventType {
  Anniversary = "ANNIVERSARY",
  Birthday = "BIRTHDAY",
  IsOff = "IS_OFF",
  Probationary = "PROBATIONARY_PERIOD_ENDING",
  Starting = "STARTING",
}

export enum Period {
  Day = "DAY",
  Week = "WEEK",
}

export interface CalendarResponse {
  fetchCalendarData: {
    nodes: CalendarDataType[];
    pageInfo: PageInfo;
  };
}

export interface CalendarLeaveData {
  dates: string[];
  reason: LeaveRequestsReason;
  employeeNote?: string;
  fromDateNo?: string;
  toDateNo?: string;
  fromDate: string;
  toDate: string;
  fromTime?: string;
  toTime?: string;
  usedDays?: number;
  dayCompleteness: LeaveRequestsDayCompleteness;
  workingConditionDuringLeave: WorkingCondition;
  status: LeaveRequestsStatus;
}

export interface GenerateClassNameArg {
  date: string;
  reason?: LeaveRequestsReason;
  fromDateNo?: string;
  toDateNo?: string;
  dayCompleteness?: LeaveRequestsDayCompleteness;
  isPrevDateLeave?: boolean;
  isNextDateLeave?: boolean;
  workingCondition: WorkingCondition;
  status?: LeaveRequestsStatus;
  nextDateWorkingCondition: WorkingCondition;
  prevDateWorkingCondition: WorkingCondition;
}
