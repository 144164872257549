import { useMutation } from "@apollo/client";
import { DatePicker, Form, InputNumber } from "antd";
import { useForm } from "antd/lib/form/Form";
import { SuccessButton } from "components/Buttons";
import { InputDropDown, InputText } from "components/Inputs";
import ALERTS from "config/alerts";
import DATE_FORMAT from "constants/dateFormat";
import dayjs from "dayjs";
import {
  AdjustHolidayLeaveFormArg,
  CreateHolidayDayAdjustmentResponse,
  EditDayEnum,
  editDayLabels,
  LeaveRequestsReason,
  leaveTypeLabels,
} from "model/Leave";
import { useParams } from "react-router-dom";
import { useNotify } from "services/api";
import { CREATE_HOLIDAY_DAY_ADJUSTMENT, FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE } from "services/graphql/leave";
import { enumValues } from "utils/misc";

type AdjustHolidayLeaveFormProps = {
  onFieldsChange: () => void;
  loading: boolean;
  hideModal: () => void;
};
const AdjustHolidayLeaveForm = ({ onFieldsChange, loading, hideModal }: AdjustHolidayLeaveFormProps) => {
  const [form] = useForm();
  const { id } = useParams();
  const notify = useNotify();

  const [mutate] = useMutation<CreateHolidayDayAdjustmentResponse>(CREATE_HOLIDAY_DAY_ADJUSTMENT, {
    onCompleted: (response) => {
      if (response.createHolidayDayAdjustment.errors?.fullMessages.length) {
        response.createHolidayDayAdjustment.errors.fullMessages.map((error: string) => notify.error(undefined, error));
      } else {
        notify.success({ message: "Holiday leave days adjusted successfully." });
        hideModal();
      }
    },
    refetchQueries: [{ query: FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE, variables: { userId: id } }],
  });

  const onFinish = (value: AdjustHolidayLeaveFormArg) => {
    const { editType, amount, addedAt, ...restArguments } = value;
    const modifyArguments = {
      ...restArguments,
      addedAt: dayjs(addedAt).format(DATE_FORMAT.isoFormat),
      amount: editType === EditDayEnum.Add ? amount : amount * -1,
      userId: id,
      reason: LeaveRequestsReason.Holiday,
    };
    mutate({ variables: modifyArguments });
  };

  return (
    <Form
      layout="vertical"
      className="p-0"
      form={form}
      autoComplete="off"
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      initialValues={{ editType: EditDayEnum.Add }}
    >
      <Form.Item label="Date" name="addedAt" rules={[ALERTS.required]} required={false}>
        <DatePicker
          className="w-100"
          changeOnBlur
          data-testid="addedAt"
          format={DATE_FORMAT.datePickerAllowDate}
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
        />
      </Form.Item>
      <Form.Item label="Add or remove available days" className="m-0" required={false}>
        <div className="d-flex justify-content-between gap-2">
          <Form.Item name="editType" rules={[ALERTS.required]} className="w-100">
            <InputDropDown
              options={enumValues(EditDayEnum).map((l) => ({ label: editDayLabels[l], value: l }))}
              placeholder="Please select"
              className="w-100"
              data-testid="editType"
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Form.Item>
          <Form.Item name="amount" rules={[ALERTS.required]} className="w-100">
            <InputNumber min={0} controls={false} className="w-100" data-testid="amount" />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item label="Notes" name="notes">
        <InputText.TextArea autoSize={{ minRows: 5 }} data-testid="notes" />
      </Form.Item>
      <SuccessButton loading={loading} className="w-100" htmlType="submit">
        Save changes
      </SuccessButton>
    </Form>
  );
};
export default AdjustHolidayLeaveForm;
