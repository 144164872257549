import { Col, Form, InputNumber, Row, Typography } from "antd";
import { InputDropDown } from "components/Inputs";
import { Currency, PaymentType, paymentTypeLabel } from "model/Employee";
import { enumValues } from "utils/misc";

const SalaryDetails = () => (
  <Row>
    <Col span={24}>
      <Row>
        <Col span={24}>
          <Typography.Title className="border-bottom pb-3 mt-5 mb-0" level={3}>
            Salary information
          </Typography.Title>
        </Col>
      </Row>
      <Row className="mt-4" gutter={[38, 0]}>
        <Col md={24} lg={8} xl={7}>
          <Row gutter={[16, 0]}>
            <Col span={16}>
              <Form.Item label="Amount" name="amount" className="mb-0">
                <InputNumber min={0} controls={false} className="w-100" data-testid="amount" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Currency" name="currency" className="mb-0">
                <InputDropDown
                  placeholder="Please select"
                  options={enumValues(Currency).map((l) => ({ label: l, value: l }))}
                  allowClear
                  data-testid="currency"
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={24} lg={8} xl={7}>
          <Form.Item label="Type" name="paymentType" className="mb-0">
            <InputDropDown
              placeholder="Please select"
              options={enumValues(PaymentType).map((l) => ({ label: paymentTypeLabel[l], value: l }))}
              allowClear
              data-testid="paymentType"
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  </Row>
);

export default SalaryDetails;
