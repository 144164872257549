import { Dayjs } from "dayjs";
import {
  ValidationErrors,
  WorkingCondition,
  PageInfo,
  DefaultErrorSuccessResponse,
  WorkingTimeEnum,
  WeekDayEnum,
  WorkingDays,
} from "./Common";
import { AnnualLeaveEnum, LeavingRequest } from "./Leave";
import { BirthdaysThisWeek, WorkAnniversaryThisWeek } from "./Calendar";
import { DefaultNotificationSettingRef, WorkingConditionPayload } from "./AdminSetting";

export type EmployeeId = string & { readonly __tag: unique symbol };

export type Label = {
  id: string;
  name: string;
};

export interface Employee {
  id: EmployeeId;
  employeeNumber: number | null;
  name: string;
  email: string;
  phone: string | null;
  jobTitle: Label | null;
  departments: Label[];
  location: Label | null;
  lineManagers: Label[];
  startedAt: string;
  loggedIn: boolean;
  birthDayReminder?: string;
  anniversaryReminder?: string;
  leavingRequest: LeavingRequest | null;
  roles: Label[];
  profileImageUrl: string | null;
  workingCondition: WorkingCondition;
}

export type CreateEmployeePayload = {
  name: string;
  email: string;
  departmentIds: string[];
  jobTitleId: string[];
  locationId: string;
  lineManagerIds: string[];
  startedAt: string;
};

export interface CreateEmployeeResponse {
  createUser: {
    errors?: ValidationErrors;
    user: Employee;
  };
}
export interface FetchEmployeeResponse {
  users: {
    nodes: Employee[];
    edges: Object;
    pageInfo: PageInfo;
  };
  birthdaysThisWeek?: { nodes: Pick<BirthdaysThisWeek, "id" | "dateOfBirth">[] };
  workAnniversaryThisWeek?: { nodes: Pick<WorkAnniversaryThisWeek, "id" | "startedAt">[] };
}

export interface EmployeeRefetch {
  refetch(): void;
}

export interface UploadedEmployee {
  id: string;
  title: string;
  uploadedItems: number;
  failedItems: number;
}

export interface UploadedEmployeeResponse {
  total: number;
  failed: number;
  employee: UploadedEmployee[];
}

interface FailedFields {
  field: string;
  errorMessage: string;
}

export interface UploadedFieldsStatusDetails {
  id: string;
  successFields: string[];
  failedFields: FailedFields[];
}

export interface FilterType {
  searchValue: string;
  locations: string[];
  departments: string[];
  showLeavers: boolean;
}

export enum Currency {
  Eur = "EUR",
  Gbp = "GBP",
  Usd = "USD",
}

export enum PaymentType {
  Annual = "ANNUAL",
  BiWeekly = "BI_WEEKLY",
  Daily = "DAILY",
  Fixed = "FIXED",
  Hourly = "HOURLY",
  Monthly = "MONTHLY",
  Quarterly = "QUARTERLY",
  Weekly = "WEEKLY",
}

export const paymentTypeLabel = {
  [PaymentType.Annual]: "Annual",
  [PaymentType.BiWeekly]: "Bi-weekly",
  [PaymentType.Daily]: "Daily",
  [PaymentType.Fixed]: "Fixed",
  [PaymentType.Hourly]: "Hourly",
  [PaymentType.Monthly]: "Monthly",
  [PaymentType.Quarterly]: "Quarterly",
  [PaymentType.Weekly]: "Weekly",
};

export enum ContractType {
  FixedTerm = "FIXED_TERM",
  MaternityCover = "MATERNITY_COVER",
  Permanent = "PERMANENT",
}

export const contractTypeLabel = {
  [ContractType.FixedTerm]: "Fixed-term",
  [ContractType.MaternityCover]: "Maternity cover",
  [ContractType.Permanent]: "Permanent",
};

export enum Gender {
  Male = "MALE",
  Female = "FEMALE",
  NotDisclosed = "NOT_DISCLOSED",
}

export const genderLabel = {
  [Gender.Female]: "Female",
  [Gender.Male]: "Male",
  [Gender.NotDisclosed]: "Other",
};

export interface EmployeeDetailArgs {
  address: string | null;
  amount?: number | null;
  annualLeave?: AnnualLeaveEnum;
  contractType?: ContractType | null;
  currency?: Currency | null;
  dateOfBirth: string | null;
  daysPerWeek?: WeekDayEnum[];
  departmentIds?: string[];
  email?: string;
  emergencyAddress: string | null;
  emergencyEmail: string | null;
  emergencyName: string | null;
  emergencyPhone: string | null;
  emergencyRelationship: string | null;
  employeeNumber?: number | null;
  endTime?: string;
  endedAt?: string | null;
  gender: Gender | null;
  holidaysPerYear?: number;
  hoursPerWeek?: number;
  jobTitleId?: string;
  lineManagerIds?: string[];
  locationId?: string;
  name: string;
  paymentType?: PaymentType | null;
  personalEmail: string | null;
  personalPhone: string | null;
  phone?: string;
  probationaryPeriod?: number;
  startTime?: string;
  startedAt?: string;
  maxHolidaysFromLastYear?: number;
  profilePicture?: File | null;
  workingCondition?: Record<string, WorkingConditionPayload>;
  workingTime: WorkingTimeEnum;
  lunchBreak?: number | null;
}

export interface ModifyEmployeeDetailArg
  extends Omit<
    EmployeeDetailArgs,
    "workingCondition" | "workingTime" | "hoursPerWeek" | "startTime" | "endTime" | "daysPerWeek" | "lunchBreak"
  > {
  workingTime?: WorkingTimeEnum;
  workingDays?: WorkingDays[];
}

export interface PersonalDetails {
  id: string;
  name: string;
  gender: Gender | null;
  employeeNumber: number | null;
  dateOfBirth: string | null;
  address: string | null;
  personalEmail: string | null;
  personalPhone: string | null;
  profileImageUrl?: string | null;
}

export interface PersonalDetailsResponse {
  fetchPersonalDetail: PersonalDetails;
}

export interface EmergencyContact {
  address: string | null;
  email: string | null;
  id: string;
  name: string | null;
  phone: string | null;
  relationship: string | null;
}
export interface Salary {
  amount: number | null;
  currency: Currency | null;
  id: string;
  paymentType: PaymentType | null;
}

export interface JobDetail {
  id: string;
  annualLeave: AnnualLeaveEnum;
  contractType: ContractType | null;
  email: string;
  endedAt: string | null;
  holidaysPerYear: number;
  maxHolidaysFromLastYear: number;
  hoursPerWeek: number;
  departments: Label[];
  jobTitle: Label;
  lineManagers: Label[];
  location: Label;
  phone: string | null;
  probationaryPeriod: number;
  startedAt: string;
  workingCondition: WorkingCondition;
}

export interface EmployeeOverViewResponse {
  fetchEmergencyContact: EmergencyContact | null;
  fetchJobDetail: JobDetail;
  fetchSalary: Salary | null;
}

export interface EmployeeFullDetail extends PersonalDetailsResponse, EmployeeOverViewResponse {}

export interface EmployeeProfileFullDetail {
  fetchProfileOverview: EmployeeFullDetail;
}

export interface EmployeeEditProfileFullDetail {
  fetchEditProfileOverview: EmployeeFullDetail;
}

export interface ModifyJobDetail extends Omit<JobDetail, "workingCondition"> {
  workingCondition?: WorkingCondition;
}

export interface ModifyEmployeeFullDetail extends Omit<EmployeeFullDetail, "fetchJobDetail"> {
  fetchJobDetail: ModifyJobDetail;
}

export interface ModifyJobDetailResponse
  extends Omit<
    JobDetail,
    "workingCondition" | "startedAt" | "endedAt" | "departments" | "jobTitle" | "lineManagers" | "location" | "id"
  > {
  startedAt?: Dayjs;
  endedAt?: Dayjs;
  workingCondition?: Record<string, WorkingConditionPayload>;
  startTime?: Dayjs;
  endTime?: Dayjs;
  daysPerWeek?: WeekDayEnum[];
  departmentIds: string[];
  jobTitleId: string;
  lineManagerIds: string[];
  locationId: string;
  departments?: Label[];
  jobTitle?: Label;
  lineManagers?: Label[];
  location?: Label;
  workingTime: WorkingTimeEnum;
  lunchBreak?: number | null;
}

export interface ModifyEmergencyContact {
  emergencyAddress: string;
  emergencyEmail: string;
  emergencyId: string;
  emergencyName: string;
  emergencyPhone: string;
  emergencyRelationship: string;
}

export interface EditEmployeeDetailResponse {
  upsertPersonalDetail?: {
    errors: ValidationErrors;
    personalDetail: PersonalDetails;
  };
  upsertJobDetail?: {
    errors: ValidationErrors;
    jobDetail: JobDetail;
  };
  upsertEmergencyContact?: {
    errors: ValidationErrors;
    emergencyContact: EmergencyContact;
  };
  upsertSalary?: {
    errors: ValidationErrors;
    salary: Salary;
  };
}

export interface UpsertProfileImageResponse {
  upsertProfileImage: {
    errors: ValidationErrors;
  };
}
export interface DestroyProfileImageResponse {
  destroyProfileImage: {
    success: boolean;
  };
}

export interface AnniversaryAndBirthdayDatatype {
  id: string;
  name: string;
  celebration: string;
}

export interface EmployeeReportsResponse {
  exportUsers: {
    presignedUrl: string;
  };
}
export enum PersonalNotificationSettingsEventType {
  ApprovedLeave = "LEAVE_REQUEST_APPROVED",
  DeclinedLeave = "LEAVE_REQUEST_DECLINED",
  DocumentShared = "DOCUMENT_SHARED",
}

export const personalNotificationSettingsEventTypeLabel: Record<PersonalNotificationSettingsEventType, string> = {
  [PersonalNotificationSettingsEventType.ApprovedLeave]: "Notify me when my leave gets approved",
  [PersonalNotificationSettingsEventType.DeclinedLeave]: "Notify me when my leave gets declined",
  [PersonalNotificationSettingsEventType.DocumentShared]: "Notify me when documents are shared",
};

export interface PersonalNotificationSettings {
  eventType: PersonalNotificationSettingsEventType;
  id: string;
  isEmail: boolean;
  isWeb: boolean;
}

export interface PersonalNotificationSettingsResponse {
  personalNotificationSettings: {
    nodes: PersonalNotificationSettings[];
    pageInfo: PageInfo;
  };
}

export interface UpdatePersonalNotificationSettingResponse {
  [x: string]: {
    defaultNotificationSetting: PersonalNotificationSettings;
    errors: ValidationErrors;
  };
}

export interface UpdatePersonalNotificationSetting {
  data: {
    [x: string]: PersonalNotificationSettings;
  };
}

export interface PersonalNotificationSettingRef extends DefaultNotificationSettingRef {}

export interface ChangePasswordRef extends DefaultNotificationSettingRef {}

export interface DestroyUserResponse {
  destroyUser: DefaultErrorSuccessResponse;
}

export interface ImportEmployeesResponse {
  bulkImport: {
    importedRecords: number;
  };
}

export interface ImportEmployeeNotifyPoints {
  allow: boolean;
  point: string;
}

export interface CalculateDayOffResponse {
  calculateDaysOff: {
    remainingDays: number;
  };
}

export interface PersonalDetailRef {
  profilePicture?: File | null;
  removeProfilePicture: boolean;
}

export interface EmployeeInitialWorkingCondition {
  workingCondition?: WorkingConditionPayload;
  daysPerWeek?: WeekDayEnum[];
  startTime?: Dayjs;
  endTime?: Dayjs;
  lunchBreak?: number | null;
  workingTime: WorkingTimeEnum;
  hoursPerWeek?: number;
}
