import { WorkingCondition, WorkingTimeEnum } from "model/Common";
import { calculateHoursPerWeek, formatWorkingDay, getSequenceOfDays } from "utils";
import TIME_FORMAT from "constants/timeFormat";
import dayjs from "dayjs";
import { Typography } from "antd";
import styled from "styled-components";
import COLORS from "constants/colors";
import { WorkingConditionPayload } from "model/AdminSetting";
import { EmployeeDataItem } from "./OverviewTab";

type Props = {
  workingCondition?: WorkingCondition;
};

const WorkingConditionDetails = ({ workingCondition }: Props) => {
  const hoursPerWeek = () => {
    if (workingCondition) {
      if (workingCondition.workingTime === WorkingTimeEnum.Fixed) {
        return calculateHoursPerWeek({
          daysPerWeek: workingCondition?.workingDays.length,
          startTimeData: dayjs(workingCondition?.workingDays[0].startTime, TIME_FORMAT.timeFormatWith24Hour),
          endTimeData: dayjs(workingCondition?.workingDays[0].endTime, TIME_FORMAT.timeFormatWith24Hour),
          lunchBreak: workingCondition?.workingDays[0].lunchBreak,
        });
      }
      const modifyWorkingCondition = workingCondition.workingDays.reduce(
        (acc, day) => ({
          ...acc,
          [day.weekDay]: formatWorkingDay(day),
        }),
        {} as Record<string, WorkingConditionPayload>,
      );
      return calculateHoursPerWeek({ workingCondition: modifyWorkingCondition });
    }
    return 0;
  };

  const formatTime = (timeString: string) =>
    dayjs(timeString, TIME_FORMAT.timeFormatWith24Hour, true).format(TIME_FORMAT.timeWithPeriodSecondary);

  return (
    <>
      <EmployeeDataItem title="Hours per week" value={hoursPerWeek()} />
      {workingCondition?.workingTime === WorkingTimeEnum.Fixed && (
        <>
          <EmployeeDataItem title="Break (min)" value={workingCondition?.workingDays[0].lunchBreak} />
          <EmployeeDataItem
            title="Start time"
            value={
              workingCondition?.workingDays[0].startTime &&
              dayjs(workingCondition?.workingDays[0].startTime, TIME_FORMAT.timeFormatWith24Hour).format(
                TIME_FORMAT.timeWithPeriodSecondary,
              )
            }
          />
          <EmployeeDataItem
            title="End time"
            value={
              workingCondition?.workingDays[0].endTime &&
              dayjs(workingCondition?.workingDays[0].endTime, TIME_FORMAT.timeFormatWith24Hour).format(
                TIME_FORMAT.timeWithPeriodSecondary,
              )
            }
          />
          <EmployeeDataItem
            title="Days per week"
            value={
              workingCondition?.workingDays.length
                ? `${workingCondition?.workingDays.length} (${getSequenceOfDays(
                    workingCondition?.workingDays.map((item) => item.weekDay),
                  )})`
                : ""
            }
          />
        </>
      )}
      {workingCondition?.workingTime === WorkingTimeEnum.Variable && (
        <EmployeeDataItem
          title="Days/time available for work"
          value={workingCondition.workingDays.map((item) => {
            const a = item.startTime;
            return (
              <WorkingDetailWrapper key={item.weekDay}>
                <WeekDayTitle level={5}>{item.weekDay}</WeekDayTitle>
                <WorkingTimeDetailsWrapper>
                  <WorkingTimeDetails>{`Start time: ${formatTime(item.startTime)}`}</WorkingTimeDetails>
                  <WorkingTimeDetails>{`End time: ${formatTime(item.endTime)}`}</WorkingTimeDetails>
                  <WorkingTimeDetails>{`Break(min): ${item.lunchBreak}`}</WorkingTimeDetails>
                </WorkingTimeDetailsWrapper>
              </WorkingDetailWrapper>
            );
          })}
        />
      )}
    </>
  );
};

export default WorkingConditionDetails;

const WorkingDetailWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.borderLight};
  padding: 11px 0px;
  &:first-child {
    padding: 9px 0px;
  }
`;

const WorkingTimeDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;

  & div:last-child {
    max-width: 110px;
    min-width: 76px;
  }
`;

const WeekDayTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 14px;
    margin-bottom: 8px;
    line-height: 12px;
  }
`;

const WorkingTimeDetails = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  width: 100%;
`;
