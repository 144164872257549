import { Col, Form, Row, Typography } from "antd";
import { InputText } from "components/Inputs";
import ALERTS from "config/alerts";

const EmergencyDetails = () => (
  <Row>
    <Col span={24}>
      <Row>
        <Col span={24}>
          <Typography.Title className="border-bottom pb-3 mt-5 mb-0" level={3}>
            Emergency contact
          </Typography.Title>
        </Col>
      </Row>
      <Row className="mt-4" gutter={[38, 0]}>
        <Col md={24} lg={8} xl={7}>
          <Form.Item label="Name" name="emergencyName">
            <InputText data-testid="emergencyName" />
          </Form.Item>
        </Col>
        <Col md={24} lg={8} xl={7}>
          <Form.Item label="Relationship" name="emergencyRelationship">
            <InputText data-testid="emergencyRelationship" />
          </Form.Item>
        </Col>
        <Col md={24} lg={8} xl={7}>
          <Form.Item label="Email" name="emergencyEmail" rules={[ALERTS.email]}>
            <InputText type="email" data-testid="emergencyEmail" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[38, 0]}>
        <Col md={24} lg={8} xl={7}>
          <Form.Item label="Phone number" name="emergencyPhone" className="mb-0">
            <InputText data-testid="emergencyPhone" />
          </Form.Item>
        </Col>

        <Col md={24} lg={16} xl={14}>
          <Form.Item label="Address" name="emergencyAddress" className="mb-0">
            <InputText data-testid="emergencyAddress" />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  </Row>
);

export default EmergencyDetails;
