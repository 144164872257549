import EMPLOYEE from "constants/employee";
import { IntlType, WorkingConditionPayload } from "model/AdminSetting";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import dayjs, { Dayjs } from "dayjs";
import { expectNumber } from "utils";
import { WeekDayEnum, WorkingDays, WorkingTimeEnum } from "model/Common";
import TIME_FORMAT from "constants/timeFormat";

dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(weekday);

export const getUserTimeZone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return timeZone;
};

export const getSequenceOfDays = (data: string[]) => {
  const days = EMPLOYEE.days.map((item) => item.label);
  const daysIdArray = EMPLOYEE.days.filter((item) => data.includes(item.value as string)).map((item) => item.id);
  const ranges = daysIdArray
    .reduce((acc: (string | number)[][], val: number, i: number) => {
      if (i === 0 || val !== daysIdArray[i - 1] + 1) acc.push([]);
      acc[acc.length - 1].push(val);
      return acc;
    }, [])
    .map((range: (string | number)[]) =>
      range.length === 1
        ? days[expectNumber(range[0] as string) - 1]
        : `${days[expectNumber(range[0] as string) - 1]}-${days[expectNumber(range[range.length - 1] as string) - 1]}`,
    );
  return ranges.join(",");
};

export const getTimeZone = () => {
  const timeZoneIdentifiers: string[] = (Intl as typeof Intl & typeof IntlType).supportedValuesOf("timeZone");
  return [...timeZoneIdentifiers, ...(!timeZoneIdentifiers.includes("UTC") ? ["UTC"] : []), "GMT"];
};

export const isToday = (date: number, currentDate: Dayjs) => {
  const monthDate = dayjs(currentDate).startOf("month");
  const newDay = monthDate.clone().add(date, "day");
  const isToday = dayjs().isSame(newDay, "day") && dayjs().isSame(newDay, "month") && dayjs().isSame(newDay, "year");
  return isToday;
};

const getTotalDayTime = (start?: Dayjs, end?: Dayjs, lunchBreak?: number | null) => {
  if (start && end) {
    const startTime = dayjs(start).hour() * 60 + dayjs(start).minute();
    const endTime = dayjs(end).hour() * 60 + dayjs(end).minute();
    const duration = ((endTime - startTime) / 60).toFixed(2);
    const breakTime = (lunchBreak ? lunchBreak / 60 : 0).toFixed(2);
    return parseFloat(duration) - parseFloat(breakTime);
  }
  return 0;
};

export const calculateHoursPerWeek = ({
  workingCondition,
  daysPerWeek,
  startTimeData,
  endTimeData,
  lunchBreak,
}: {
  workingCondition?: Record<string, WorkingConditionPayload>;
  daysPerWeek?: number;
  startTimeData?: Dayjs;
  endTimeData?: Dayjs;
  lunchBreak?: number | null;
}): number => {
  if (workingCondition) {
    return Object.values(workingCondition).reduce((totalHours: number, day: WorkingConditionPayload) => {
      if (day.day) {
        return totalHours + getTotalDayTime(day.startTime, day.endTime, day.lunchBreak);
      }
      return totalHours;
    }, 0);
  }
  return daysPerWeek ? getTotalDayTime(startTimeData, endTimeData, lunchBreak) * daysPerWeek : 0;
};

export const modifyWorkingConditionPayload = ({
  workingTime,
  workingConditionPayload,
  daysPerWeek,
  startTimeData,
  endTimeData,
  lunchBreak,
}: {
  workingTime: WorkingTimeEnum;
  workingConditionPayload?: Record<string, WorkingConditionPayload>;
  daysPerWeek?: WeekDayEnum[];
  startTimeData?: string;
  endTimeData?: string;
  lunchBreak?: number | null;
}) => {
  let workingDays: WorkingDays[] = [];
  if (workingTime === WorkingTimeEnum.Fixed) {
    workingDays = (daysPerWeek || []).map((item) => ({
      endTime: dayjs(endTimeData).format(TIME_FORMAT.timeFormatWith24Hour),
      lunchBreak: lunchBreak ?? 0,
      startTime: dayjs(startTimeData).format(TIME_FORMAT.timeFormatWith24Hour),
      weekDay: item,
    }));
  } else {
    workingDays = workingConditionPayload
      ? Object.keys(workingConditionPayload).reduce((acc, day) => {
          if (workingConditionPayload[day].day) {
            return [
              ...acc,
              {
                endTime: dayjs(workingConditionPayload[day].endTime).format(TIME_FORMAT.timeFormatWith24Hour),
                lunchBreak: workingConditionPayload[day].lunchBreak ?? 0,
                startTime: dayjs(workingConditionPayload[day].startTime).format(TIME_FORMAT.timeFormatWith24Hour),
                weekDay: day as WeekDayEnum,
              },
            ];
          }
          return acc;
        }, [] as WorkingDays[])
      : [];
  }

  return workingDays;
};

export const formatWorkingDay = ({ startTime, endTime, lunchBreak }: WorkingDays) =>
  startTime
    ? {
        day: true,
        startTime: dayjs(startTime, TIME_FORMAT.timeFormatWith24Hour),
        endTime: dayjs(endTime, TIME_FORMAT.timeFormatWith24Hour),
        lunchBreak,
      }
    : { day: false };

export default {
  getUserTimeZone,
  getSequenceOfDays,
  getTimeZone,
  isToday,
  calculateHoursPerWeek,
  modifyWorkingConditionPayload,
  formatWorkingDay,
};
