import AntIcon, {
  ArrowDownOutlined,
  ArrowUpOutlined,
  FilterOutlined,
  LeftOutlined,
  MailOutlined,
  MenuOutlined,
  PlusOutlined,
  PrinterOutlined,
  RightOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import COLORS, { ColorType } from "constants/colors";
import { forwardRef } from "react";

import CustomIcons from "./icons";

const ICON_SIZES = {
  small: "12px",
  medium: "14px",
  large: "18px",
  xlarge: "25px",
};

export const customIconList = {
  arrowRight: CustomIcons.ArrowRightSvg,
  arrowLeft: CustomIcons.ArrowLeftSvg,
  download: CustomIcons.DownloadSvg,
  "progress-score": CustomIcons.ProgressScoreSvg,
  star: CustomIcons.StarSvg,
  home: CustomIcons.HomeSvg,
  plus: () => <PlusOutlined />,
  manage: () => <SettingOutlined />,
  account: () => <UserOutlined />,
  down: CustomIcons.DownSvg,
  up: () => <CustomIcons.DownSvg style={{ transform: "rotate(180deg)" }} />,
  menu: () => <MenuOutlined />,
  filter: () => <FilterOutlined />,
  right: () => <RightOutlined />,
  left: () => <LeftOutlined />,
  printer: () => <PrinterOutlined />,
  remove: CustomIcons.RemoveSvg,
  edit: CustomIcons.EditSvg,
  email: () => <MailOutlined />,
  search: CustomIcons.SearchSvg,
  info: CustomIcons.InfoSvg,
  sick: CustomIcons.SickLeave,
  carers: CustomIcons.CareersLeave,
  jury_service: CustomIcons.JuryService,
  paternity: CustomIcons.PaternityLeave,
  adoptive: CustomIcons.AdoptiveLeave,
  compassionate: CustomIcons.CompassionateLeave,
  force_majeure: CustomIcons.ForceMajeureLeave,
  maternity: CustomIcons.MaternityLeave,
  other: CustomIcons.OtherLeave,
  parental: CustomIcons.ParentalLeave,
  time_in_lieu: CustomIcons.TimeInLieuLeave,
  unpaid: CustomIcons.UnpaidLeave,
  settings: CustomIcons.SettingsSvg,
  user: CustomIcons.UserOutlinedSvg,
  userFilled: CustomIcons.UserFilledSvg,
  arrowUp: () => <ArrowUpOutlined />,
  arrowDown: () => <ArrowDownOutlined />,
  birthDay: CustomIcons.BirthdaySvg,
  anniversary: CustomIcons.AnniversarySvg,
  popover: CustomIcons.PopOverSvg,
  check: CustomIcons.CheckOutlinedSvg,
  successCheck: CustomIcons.SuccessCheckSvg,
  document: CustomIcons.DocumentSvg,
  calendar: CustomIcons.CalendarSvg,
  calendarBlack: CustomIcons.CalendarBlackSvg,
  bell: CustomIcons.BellSvg,
  uploadComplete: CustomIcons.UploadComplete,
  danger: CustomIcons.DangerSvg,
  documentPage: CustomIcons.DocumentPageSvg,
  visaCard: CustomIcons.VisaCard,
  masterCard: CustomIcons.MasterCard,
  amexCard: CustomIcons.AmexCard,
  discoverCard: CustomIcons.DiscoverCard,
  dinersCard: CustomIcons.DinersCard,
  jcbCard: CustomIcons.JcbCard,
  unionPayCard: CustomIcons.UnionPayCard,
  warningSvg: CustomIcons.WarningSvg,
  noSubscription: CustomIcons.NoSubscription,
  delete: CustomIcons.DeleteSvg,
  infoOutline: CustomIcons.InfoOutlineSvg,
};

export type ICustomIconList = keyof typeof customIconList;
export type ICustomIconSizes = keyof typeof ICON_SIZES;

interface IconProps {
  name: ICustomIconList;
  color?: ColorType;
  size?: ICustomIconSizes;
  inline?: boolean;
  [x: string]: any;
}

const Icon = forwardRef<HTMLElement, IconProps>(({ name, color, size, inline, ...props }, ref) => {
  let updatedProps = props;
  if (color) {
    updatedProps = { ...updatedProps, style: { ...updatedProps.style, color: COLORS[color] } };
  }
  if (size) {
    updatedProps = { ...updatedProps, style: { ...updatedProps.style, fontSize: ICON_SIZES[size] } };
  }

  if (inline) {
    updatedProps = { ...updatedProps, style: { ...updatedProps.style, display: "inline" } };
  }

  const IconSvg = customIconList[name];
  if (IconSvg) return <AntIcon component={IconSvg} {...updatedProps} ref={ref} title="icon" />;
  return null;
});

export default Icon;

Icon.defaultProps = {
  color: undefined,
  size: undefined,
};
