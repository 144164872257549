import { SortOrderEnum } from "components/Table";

export const TABLE = {
  rowsPerPage: 20,
  minRowsPerPage: 10,
  tableDefaultHeight: 500,
  tableMinHeight: 277,
  tableMaxHeight: 600,
  tableHeight: 180,
  maxRowPerPage: 25,
  tableSortDirections: [SortOrderEnum.Descend, SortOrderEnum.Ascend, SortOrderEnum.Descend],
};
export default TABLE;
