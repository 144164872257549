import {
  Checkbox,
  Col,
  Form,
  FormInstance,
  InputNumber,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Typography,
} from "antd";
import Icon from "components/Icon";
import { InputDropDown } from "components/Inputs";
import UnsavedPrompt from "components/UnsavedPrompt";
import { Container } from "components/core";
import ALERTS from "config/alerts";
import FORM from "constants/form";
import {
  DefaultSettingInitialValue,
  DefaultSettingPayload,
  ShowEmployeeCalendarType,
  showEmployeeCalendarLabel,
} from "model/AdminSetting";
import { AnnualLeaveEnum, annualLeaveLabel } from "model/Leave";
import styled from "styled-components";
import { expectNumber, getTimeZone } from "utils";
import { enumValues } from "utils/misc";
import { VariableDailyTimeShowFieldReset, WorkingTimeEnum, workingTimeLabel } from "model/Common";
import { useRef } from "react";
import WorkingConditionFormFields from "./WorkingConditionFormFields";

type DefaultSettingsFormProps = {
  isFieldsChanged: boolean;
  setIsFieldsChanged: (value: boolean) => void;
  onFinish: (data: DefaultSettingPayload) => void;
  formInitialValues?: DefaultSettingInitialValue;
  showEmployeeCalendarValue: number[];
  selectedCalendarsOnChange: (value: number, checked: boolean) => void;
  form: FormInstance;
};
const DefaultSettingsForm = ({
  setIsFieldsChanged,
  onFinish,
  formInitialValues,
  showEmployeeCalendarValue,
  isFieldsChanged,
  selectedCalendarsOnChange,
  form,
}: DefaultSettingsFormProps) => {
  const workingConditionRef = useRef<VariableDailyTimeShowFieldReset>(null);
  const workingTime = Form.useWatch("workingTime", form);

  const handleWorkingTimeChange = (e: RadioChangeEvent) => {
    form.setFieldsValue({
      workingCondition: undefined,
      hoursPerWeek: undefined,
      startTime: undefined,
      endTime: undefined,
      daysPerWeek: undefined,
    });
    workingConditionRef?.current?.variableDailyTimeShowFieldReset();
    if (formInitialValues?.workingTime === WorkingTimeEnum.Fixed && e.target.value === WorkingTimeEnum.Fixed) {
      form.setFieldsValue({
        startTime: formInitialValues?.startTime,
        endTime: formInitialValues?.endTime,
        daysPerWeek: formInitialValues?.daysPerWeek,
        hoursPerWeek: formInitialValues?.hoursPerWeek,
      });
    } else if (
      formInitialValues?.workingTime === WorkingTimeEnum.Variable &&
      e.target.value === WorkingTimeEnum.Variable
    ) {
      form.setFieldsValue({
        workingCondition: formInitialValues?.workingCondition,
        hoursPerWeek: formInitialValues?.hoursPerWeek,
      });
    }
  };

  return (
    <StyledContainer className="section-box-shadow">
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ lg: 10, xl: 8, xxl: 7 }}
        labelAlign="left"
        id={FORM.defaultSettingForm}
        onValuesChange={() => setIsFieldsChanged(true)}
        onFinish={(data) => onFinish(data)}
        initialValues={formInitialValues}
      >
        <Row data-testid="working">
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Typography.Title className="border-bottom pb-3 m-0" level={3}>
                  Working days and hours
                </Typography.Title>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={24}>
                <Form.Item
                  name="workingTime"
                  colon={false}
                  rules={[ALERTS.required]}
                  required={false}
                  className="w-100 m-0"
                >
                  <Radio.Group data-testid="workingTime" onChange={handleWorkingTimeChange}>
                    <Space direction="horizontal">
                      {enumValues(WorkingTimeEnum).map((l) => (
                        <Radio value={l} key={l}>
                          <span className={`me-2  ${workingTime === l ? "fw-600" : "fw-500"} `}>
                            {workingTimeLabel[l]}
                          </span>
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={24} className="mb-4 pb-1">
                <WorkingConditionFormFields workingTime={workingTime} form={form} ref={workingConditionRef} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="border-top pb-3 m-0">
          <Col span={24}>
            <Row className="mt-4 pt-1" gutter={[38, 0]}>
              <Col md={24} lg={24} xxl={18}>
                <Form.Item
                  label="Default holidays per year"
                  name="holidaysPerYear"
                  colon={false}
                  wrapperCol={{ lg: 11, xl: 10 }}
                  rules={[ALERTS.required]}
                  required={false}
                >
                  <InputNumber min={0} className="w-100" data-testid="holidaysPerYear" />
                </Form.Item>
                <Form.Item
                  label="Default probationary period (months)"
                  name="probationaryPeriod"
                  colon={false}
                  wrapperCol={{ lg: 11, xl: 10 }}
                  rules={[ALERTS.required]}
                  required={false}
                >
                  <InputNumber min={0} className="w-100" data-testid="probationaryPeriod" />
                </Form.Item>
                <Form.Item
                  label="Annual leave"
                  name="annualLeave"
                  colon={false}
                  wrapperCol={{ lg: 24 }}
                  rules={[ALERTS.required]}
                  required={false}
                >
                  <Radio.Group data-testid="annualLeave">
                    <Space direction="vertical">
                      {enumValues(AnnualLeaveEnum).map((l) => (
                        <Radio value={l} key={l}>
                          <span className="me-2 fw-500">{annualLeaveLabel[l].label}</span>
                          <Popover title={<StyledPopOverTitle>{annualLeaveLabel[l].popupTitle}</StyledPopOverTitle>}>
                            <Icon name="info" />
                          </Popover>
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="Default time zone"
                  name="timeZone"
                  colon={false}
                  wrapperCol={{ lg: 11, xl: 10 }}
                  rules={[ALERTS.required]}
                  required={false}
                >
                  <InputDropDown
                    options={getTimeZone()?.map((item) => ({ value: item, label: item }))}
                    className="w-100"
                    data-testid="timeZone"
                    placeholder="Please Select a time zone"
                  />
                </Form.Item>
                <Form.Item
                  label="Whose calendar can employees see"
                  name="selectedCalendars"
                  colon={false}
                  wrapperCol={{ lg: 18, xl: 16, style: { justifyContent: "center" } }}
                  valuePropName="checked"
                  className="mb-0"
                >
                  <div>
                    {enumValues(ShowEmployeeCalendarType).map((l) => (
                      <Checkbox
                        value={expectNumber(l)}
                        key={l}
                        checked={showEmployeeCalendarValue.includes(expectNumber(l))}
                        onChange={(e) => selectedCalendarsOnChange(e.target.value, e.target.checked)}
                      >
                        <span className="fw-500">{showEmployeeCalendarLabel[l]}</span>
                      </Checkbox>
                    ))}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <UnsavedPrompt when={isFieldsChanged} />
    </StyledContainer>
  );
};
export default DefaultSettingsForm;
const StyledContainer = styled(Container)`
  padding: 34px 42px 18px 42px;
  .fw-500 {
    font-weight: 500;
  }
  .fw-600 {
    font-weight: 600;
  }
`;
const StyledPopOverTitle = styled.div`
  width: 260px;
  display: block;
  text-align: center;
`;
