import { Form, FormInstance, TimePicker, TimePickerProps } from "antd";
import Icon from "components/Icon";
import COLORS from "constants/colors";
import React, { useState } from "react";
import styled from "styled-components";

const InputTimePicker = ({
  form,
  name,
  onChange,
  onSelect,
  ...pickerProps
}: Omit<TimePickerProps, "name"> & { form: FormInstance; name: string | string[] }) => {
  const value = Form.useWatch(name, form);
  const [isAmOrPm, setIsAmOrPm] = useState<string | null>(null);

  const selectAmPm = (inputValue: string) => {
    const popup = document.querySelector(`.picker_${pickerProps.id}`);
    if (!popup) return;
    const liElements = popup.querySelectorAll(".ant-picker-time-panel-column:last-child li.ant-picker-time-panel-cell");
    if (liElements.length !== 2) return;

    if (/\b(AM)\b/.test(inputValue)) {
      (liElements[0] as HTMLElement).click();
    } else if (/\b(PM)\b/.test(inputValue)) {
      (liElements[1] as HTMLElement).click();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setTimeout(() => {
      const inputValue = (e.target as HTMLInputElement).value;
      selectAmPm(inputValue);
    }, 0);
  };

  return (
    <TimePicker
      {...pickerProps}
      popupClassName={`picker_${pickerProps.id}`}
      onSelect={(value) => {
        if (onSelect) {
          onSelect(value);
        }
        if (value.format("A") !== isAmOrPm) setIsAmOrPm(value.format("A"));
      }}
      onChange={(value, dateString) => {
        if (onChange) {
          onChange(value, dateString);
        }
        if (!value) {
          setIsAmOrPm(null);
        }
      }}
      onKeyDown={handleKeyDown}
      suffixIcon={
        <StyledSuffix>
          <span data-testid="period">{isAmOrPm ?? value?.format("A")}</span>
          <Icon name="down" />
        </StyledSuffix>
      }
    />
  );
};
export default InputTimePicker;
const StyledSuffix = styled.span`
  display: flex;
  gap: 10px;
  span {
    color: ${COLORS.disabledColor};
    font-weight: 500;
    opacity: 50%;
  }
`;
